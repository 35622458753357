import React from "react";
import { Text, Box } from "rebass";

const Date = ({ date, updated = null }) => {
  if (updated === null || date === updated) {
    return (
      <Text as="small" fontSize="0">
        {date}
      </Text>
    );
  }

  return (
    <Box m="1">
      <Text
        as="small"
        fontSize="0"
        css={`
          text-decoration: line-through;
        `}
        p="1"
      >
        {date}
      </Text>

      <Text
        as="small"
        fontSize="0"
        color="white"
        bg="blue"
        p="1"
        css={`
          display: inline-block;
          transform: rotate(-5deg);
        `}
      >
        {updated}
      </Text>
    </Box>
  );
};

export default Date;
